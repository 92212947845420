import React, { useState } from "react";
import { Button } from "@material-ui/core";

import Joyride, { ACTIONS, EVENTS, STATUS } from "react-joyride";
import { makeStyles } from "@material-ui/core/styles";

// icons
import InstructionIcon from "@material-ui/icons/Assignment";
import CodeIcon from "@material-ui/icons/Code";
import AssignmentTurnedInIcon from "@material-ui/icons/AssignmentTurnedIn";

import Countdown, { zeroPad } from "react-countdown";
import Swal from "sweetalert2";
import logo from "../../assets/icons/CodingID.png";
import { Redirect } from "react-router-dom";

import UserInput from "../../components/userInput/UserInputGuide";
import Instruction from "../../components/instruction/InstructionGuide";
import Score from "../../components/score/ScoreGuide";
import Result from "../../components/result/ResultGuide";
import DialogSubmit from "../../components/submit/DialogSubmit";

let primaryColorDark = "#212B31";
let neutral_n95 = "#F5F8FF";

const useStyles = makeStyles((theme) => ({
  root: {
    // flexGrow: 1,
    // padding: "5px",
    height: "100vh",
    width: "100vw",
    fontFamily: "'Poppins', sansSerif",
    display: "flex",
  },
  sidebar: {
    maxWidth: "60px",
    backgroundColor: "#5C707D",
    paddingTop: "55px",
    padding: "55px 0px",
    display: "flex",
    flexDirection: "column",
  },
  containerButtonQuestion: {
    padding: "10px",
    borderBottom: "1px solid rgba(255, 255, 255, 0.2)",
  },
  questionButton: {
    background: "#3DC3DD",
    borderRadius: "4px",
    width: "40px",
    height: "40px",
    borderColor: "transparent",
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
  root: {
    height: "100vh",
    width: "100vw",
    fontFamily: "'Poppins', sansSerif",
    display: "flex",
  },
  navbar: {
    backgroundColor: primaryColorDark,
    height: "10vh",
    padding: "18.5px 24px",
    borderBottom: "1px solid rgba(255, 255, 255, 0.2)",
    // border: "1px solid #CFD6E5",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  questionTitle: {
    margin: "0px auto",
    textAlign: "left",
    fontWeight: "bold",
    color: neutral_n95,
    width: "100%",
  },
  countdown: {
    fontWeight: "bold",
    padding: "8px",
    color: "#B3FFE5",
    minWidth: "239px",
  },
  containerAssessment: {
    height: "90vh",
    // width: "100vw",
    display: "flex",
    justifyContent: "space-between",
    flex: 1,
  },
  containerSoal: {
    flex: 1,
    fontWeight: "600",
    // border: "1px solid #CFD6E5",

    backgroundColor: "#36454F",
  },
  containerTab: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-around",
    borderBottom: "1px solid rgba(255, 255, 255, 0.2)",
    // padding: "10px",
    // borderBottom: "1px solid #CFD6E5",
  },
  tab: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: "18.5px",
    flex: "1",
    "&:hover": {
      cursor: "pointer",
      color: "#234B88",
    },
  },
  tabActive: {
    // backgroundColor: "#CFD6E5",
    padding: "5px 15px",
    borderRadius: "5px",
  },
  textTabActive: {
    color: "#3DC3DD",
    fontSize: "15px",
  },
  textTabInactive: {
    color: "#FFFFFF",
  },
  containerNavigation: {
    padding: "20px",
  },
}));

const step = [
  {
    content: (
      <div>Pilih pertanyaan untuk dikerjakan sesuai dengan instruksi</div>
    ),
    placement: "bottom",
    target: ".question-guide",
    disableBeacon: true,
    spotlightClicks: true,
    // disableOverlayClose: true,
    title: "Choose Question",
  },
  {
    content: <div>Pilih bahasa pemrograman sesuai kemampuan diri kamu</div>,
    placement: "bottom",
    disableOverlayClose: true,
    spotlightClicks: true,
    target: ".language-guide",
    title: "Choose Language",
  },
  {
    content: <div>Tempat untuk menulis code kamu</div>,
    placement: "right",
    disableOverlayClose: true,
    spotlightClicks: true,
    target: ".editor-guide",
    title: "Text Editor",
  },
  {
    content: <div>Reset ulang Code yang ditulis di text editor</div>,
    placement: "top",
    disableOverlayClose: true,
    target: ".reset-guide",
    title: "Reset Code",
  },
  {
    content: <div>Menjalankan codingan yang sudah ditulis di text editor</div>,
    placement: "top",
    disableOverlayClose: true,
    target: ".run-guide",
    title: "Run Code",
  },
  {
    content: <div>Berisi instruksi pengerjaan soal</div>,
    placement: "left",
    disableOverlayClose: true,
    spotlightClicks: true,
    target: ".instruction-guide",
    title: "Instruction",
  },
  {
    content: (
      <div>
        Data yang telah ditentukan oleh pihak Coding ID untuk digunakan dalam
        testing.
      </div>
    ),
    placement: "left",
    disableOverlayClose: true,
    target: ".sample-input-guide",
    title: "Sample Input",
  },
  {
    content: <div>Output Data yang berasal dari code yang kamu tulis</div>,
    placement: "left",
    disableOverlayClose: true,
    target: ".your-output-guide",
    title: "Your Output",
  },
  {
    content: <div>Output data yang diharapkan muncul</div>,
    placement: "left",
    disableOverlayClose: true,
    target: ".expected-output-guide",
    title: "Expected Output",
  },
  {
    content: (
      <div>
        Menjalankan validasi code untuk menentukan apakah code kamu sudah
        berfungsi dengan baik
      </div>
    ),
    placement: "left",
    disableOverlayClose: true,
    target: ".test-case-guide",
    title: "Test Case",
  },
  {
    content: (
      <div>Rangkuman nilai yang kamu dapat selama pengerjaan assessment</div>
    ),
    placement: "left",
    disableOverlayClose: true,
    target: ".score-guide",
    title: "Score",
  },
  {
    content: <div>Batasan waktu pengerjaan</div>,
    placement: "bottom",
    disableOverlayClose: true,
    target: ".countdown-guide",
    title: "Timer",
  },
  {
    content: (
      <div>Klik button submit setelah selesai mengerjakan semua soal</div>
    ),
    placement: "bottom",
    disableOverlayClose: true,
    target: ".submit-guide",
    title: "Submit Task",
  },
  {
    content: (
      <div>Periksa kembali nilai dari setiap soal yang kamu kerjakan</div>
    ),
    placement: "top-start",

    disableOverlayClose: true,
    target: ".dialog-submit",
    title: "Review Dialog",
  },
  {
    content: <div>Klik tombol submit jika kamu sudah yakin</div>,
    placement: "top-start",

    disableOverlayClose: true,
    target: ".dialog-submit-button",
    title: "Button Submit",
  },
];

const TourGuide = () => {
  const classes = useStyles();
  const [run, setRun] = useState(true);
  const [stepIndex, setStepIndex] = useState(0);
  const [timeLeft, setTimeLeft] = useState(Date.now() + 7200 * 60000);
  const [steps, setSteps] = useState(step);
  const [tabActive, setTabActive] = useState("instruction");
  const [redirect, setRedirect] = useState(null);

  const [dialogFinish, setDialogFinish] = useState(false);

  const [scoreObject, setScoreObject] = useState({
    arrayQuestion: [
      {
        language: "-",
        maxScore: 64,
        question_code: "TEST001",
        question_name: "PSBB",
        result: "-",
      },
      {
        language: "PHP",
        maxScore: 100,
        question_code: "QCA",
        question_name: "KUY!",
        result: "80",
      },
    ],
    unsolvedQuestion: 1,
    totalQuestion: 2,
  });

  const dialogFinishOpen = () => {
    setDialogFinish(true);
  };

  const dialogFinishClose = () => {
    //console.log("Close dialog finish");
    setDialogFinish(false);
  };

  const rendererTime = ({ days, hours, minutes, seconds, completed }) => {
    if (days == 0 && hours == 0 && minutes == 5 && seconds == "00") {
      Swal.fire("Times almost up", "Please check again your answer", "info");
    }
    if (completed) {
      return <span className={classes.countdown}>Times Up!</span>;
    } else {
      if (days > 0) {
        return (
          <span className={classes.countdown}>
            Remaining Time = {zeroPad(days * 24 + hours)}:{zeroPad(minutes)}:
            {zeroPad(seconds)}
          </span>
        );
      } else {
        return (
          <span className={classes.countdown}>
            Remaining Time = {zeroPad(hours)}:{zeroPad(minutes)}:
            {zeroPad(seconds)}
          </span>
        );
      }
    }
  };

  const handleJoyrideCallback = (data) => {
    const { action, index, status, type } = data;

    if ([EVENTS.STEP_AFTER, EVENTS.TARGET_NOT_FOUND].includes(type)) {
      // Update state to advance the tour

      let step = index + (action === ACTIONS.PREV ? -1 : 1);
      console.log(step);
      if (action == "next") {
        if (step == 6) {
          setTabActive("result");
        } else if (step == 10) {
          setTabActive("score");
        } else if (step == 13) {
          setDialogFinish(true);

          // setTimeout(() => {
          //   // setDialogFinish(true);
          //   setStepIndex(step);
          // }, 1000);
        }
      } else if (action == "prev") {
        if (step == 9) {
          setTabActive("result");
          step = 6;
        } else if (step == 5) {
          setTabActive("instruction");
        } else if (step == 12) {
          setDialogFinish(false);
          // setTimeout(() => {
          //   // setDialogFinish(true);
          //   setStepIndex(step);
          // }, 1000);
        }
      }

      setTimeout(() => {
        setStepIndex(step);
      }, 100);
      // if ((step != 13 && action != "next") || (step == 12 && action !=)) {
      //   setStepIndex(step);
      // }
      // this.setState({
      //     stepIndex: index + (action === ACTIONS.PREV ? -1 : 1),
      // });
    } else if ([STATUS.FINISHED, STATUS.SKIPPED].includes(status)) {
      // Need to set our running state to false, so we can restart if we click start again.
      window.location.replace(
        `${process.env.REACT_APP_WEB_END_POINT}dashboard`
      );
    }
  };

  const renderTab = () => {
    if (tabActive == "instruction") {
      return <Instruction />;
    } else if (tabActive == "result") {
      return <Result />;
    } else if (tabActive == "score") {
      return <Score />;
    }
  };

  const renderRedirect = () => {
    if (redirect) {
      return <Redirect to={redirect} />;
    }
  };

  return (
    <>
      {renderRedirect()}
      <Joyride
        callback={handleJoyrideCallback}
        isLastStep={true}
        run={run}
        steps={steps}
        continuous
        showProgress
        showSkipButton
        stepIndex={stepIndex}
        floaterProps={{
          styles: {
            wrapper: {
              zIndex: 1500,
            },
          },
        }}
        styles={{
          buttonNext: { background: "#3f51b5" },
          options: { primaryColor: "#3f51b5", zIndex: 1500 },
          buttonSkip: {
            background: "#f74747",
            color: "#fff",
            borderRadius: "5px",
          },
        }}
      />
      <div className={classes.root}>
        <div className={classes.sidebar}>
          <img src={logo} alt="" style={{ marginBottom: "25px" }} />
          <div className="scrollbar question-guide" id="style-1">
            {[0, 1, 2].map((item, index) => (
              <div
                key={"quest-" + index}
                className={`${classes.containerButtonQuestion} `}
              >
                <button
                  // onClick={() => changeQuestionHandlerButton(index + 1)}
                  className={classes.questionButton}
                  style={
                    1 != index + 1
                      ? { backgroundColor: "transparent", color: "#FFFFFF" }
                      : {}
                  }
                >
                  {index + 1}
                </button>
              </div>
            ))}
          </div>

          <div style={{ position: "absolute", bottom: "25px" }}></div>
        </div>

        <div style={{ flex: 1 }}>
          <div className={classes.navbar}>
            {/* <img src={logo} alt="" /> */}
            <p className={classes.questionTitle}>TEST001 - PSBB</p>

            <div className="countdown-guide" style={{ minWidth: "233px" }}>
              <Countdown
                date={timeLeft}
                renderer={rendererTime}
                daysInHours={true}
              />
            </div>

            <Button
              onClick={() => {}}
              variant="contained"
              color="primary"
              style={{
                backgroundColor: "#3DC3DD",
                color: "#2B2E33",
                fontWeight: "bold",
              }}
              className="submit-guide"
            >
              Submit
            </Button>
          </div>
          <div className={classes.containerAssessment}>
            <UserInput />
            <div className={`${classes.containerSoal} soal`}>
              <div className={classes.containerTab}>
                <div
                  className={`${classes.tab} ${
                    tabActive == "instruction" ? classes.tabActive : ""
                  }
                            `}
                >
                  <span
                    className={`${
                      tabActive == "instruction"
                        ? classes.textTabActive
                        : classes.textTabInactive
                    }`}
                  >
                    <InstructionIcon
                      fontSize="small"
                      style={{ marginRight: "10px" }}
                    />
                    Instructions
                  </span>
                </div>
                <div
                  className={`${classes.tab} ${
                    tabActive == "result" ? classes.tabActive : ""
                  }
                            `}
                >
                  <span
                    className={`${
                      tabActive == "result"
                        ? classes.textTabActive
                        : classes.textTabInactive
                    }`}
                  >
                    <CodeIcon
                      fontSize="small"
                      style={{ marginRight: "10px" }}
                    />
                    Result
                  </span>
                </div>
                <div
                  className={`${classes.tab} ${
                    tabActive == "score" ? classes.tabActive : ""
                  }
                            `}
                >
                  <span
                    className={`${
                      tabActive == "score"
                        ? classes.textTabActive
                        : classes.textTabInactive
                    }`}
                  >
                    <AssignmentTurnedInIcon
                      fontSize="small"
                      style={{ marginRight: "10px" }}
                    />
                    Score
                  </span>
                </div>
                {/* <div className="submit-guide">
                <Button variant="contained" color="primary">
                  Submit
                </Button>
              </div> */}
              </div>
              <div className={classes.containerNavigation}>{renderTab()}</div>
            </div>
          </div>
        </div>
        <DialogSubmit
          dialogFinishClose={dialogFinishClose}
          dialogFinish={dialogFinish}
          finishHandler={() => {}}
          scoreObject={scoreObject}
          disabledCancel={true}
        />
      </div>
    </>
  );
};

export default TourGuide;
