import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@material-ui/core";
import { useContext } from "react";
import { ThemeContext } from "../../context/ThemeContextProvider";

const useStyles = makeStyles({
  newHeadlineH6: {
    fontFamily: "Poppins",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "19px",
    lineHeight: "28px",
    textAlign: "center",
    color: "#41454D",
    marginBottom: "0px",
  },
  newBody1Regular: {
    fontFamily: "Poppins",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "15px",
    lineHeight: "22px",
    textAlign: "left",
    color: "#41454D",
    marginBottom: "0px",
    whiteSpace: "nowrap",
    width: "100%",
  },
  buttonCancelFinish: {
    backgroundColor: "#fff",
    color: "#2f50ad",
    textTransform: "none",
    fontWeight: "500",
    borderRadius: "8px",
    padding: "12px 55px",
    outline: "none !important",
    borderColor: "#2761BA",
  },
  buttonFinish: {
    backgroundColor: "#2f50ad",
    color: "#fff",
    textTransform: "none",
    fontWeight: "500",
    borderRadius: "8px",
    padding: "12px 55px",
    outline: "none !important",
    "&:hover": {
      backgroundColor: "#2f50ad",
      color: "#fff",
    },
  },
  Row: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "flex-start",
    // maxWidth: "300px",
    flex: 1,
  },
  rowContainer: {
    paddingBottom: "16px",
    paddingTop: "16px",

    borderColor: "#CFD6E6",
    borderStyle: "solid",
    borderWidth: "0px",
    borderBottomWidth: "1px",
  },
  index: {
    fontFamily: "Poppins",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "15px",
    lineHeight: "22px",
    width: "20px",
    marginBottom: "0px",
  },
  failedStyle: {
    color: "#BF4C36",
  },
  PaddingR20: {
    // paddingRight: "20px",
    marginRight: "20px",
  },
  blue: {
    color: "#65a0f9",
  },
});

function DialogSubmit(props) {
  const {
    dialogFinishClose,
    dialogFinish,
    finishHandler,
    scoreObject,
    disabledCancel,
  } = props;
  const classes = useStyles();

  const { mode } = useContext(ThemeContext);
  return (
    <Dialog
      onClose={dialogFinishClose}
      aria-labelledby="customized-dialog-title"
      open={dialogFinish}
      style={{
        minWidth: "400px",
      }}
    >
      <DialogContent
        style={{
          paddingBottom: "24px",
          backgroundColor: mode == "dark" ? "#212529" : "white",
        }}
      >
        <h4
          className={classes.newHeadlineH6}
          style={{ color: mode == "dark" ? "white" : "#212529" }}
        >
          Are you sure to submit test ?
        </h4>
        {scoreObject.unsolvedQuestion > 0 && (
          <h6
            className={classes.newBody1Regular}
            style={{
              textAlign: "center",
              color: mode == "dark" ? "white" : "#212529",
            }}
          >
            You have {scoreObject.unsolvedQuestion} unsolved questions
          </h6>
        )}

        <hr />
        <div className={`dialog-submit`}>
          {scoreObject.arrayQuestion.map((item, index) => {
            return (
              <div
                className={`${classes.Row} ${classes.rowContainer} `}
                key={index}
              >
                <div className={`${classes.Row} ${classes.PaddingR20}`}>
                  <span
                    className={`${classes.index} ${
                      isNaN(item.result) ? classes.failedStyle : ""
                    }`}
                    style={{
                      color: isNaN(item.result)
                        ? "#BF4C36"
                        : mode == "dark"
                        ? "white"
                        : "#212529",
                    }}
                  >
                    {index + 1}.
                  </span>
                  <h6
                    className={`${classes.newBody1Regular} ${
                      isNaN(item.result) ? classes.failedStyle : ""
                    }`}
                    style={{
                      maxWidth: "300px",
                      whiteSpace: "pre-wrap",
                      color: isNaN(item.result)
                        ? "#BF4C36"
                        : mode == "dark"
                        ? "white"
                        : "#212529",
                    }}
                  >
                    {item.question_code} - {item.question_name}
                  </h6>
                </div>

                <div>
                  <h6
                    className={`${classes.newBody1Regular} ${
                      isNaN(item.result) ? classes.failedStyle : ""
                    }`}
                    style={{
                      fontWeight: "600",
                      color: isNaN(item.result)
                        ? "#BF4C36"
                        : mode == "dark"
                        ? "white"
                        : "#212529",
                    }}
                  >
                    <span
                      className={`${isNaN(item.result) ? "" : classes.blue}`}
                      style={{
                        color: isNaN(item.result) ? "#BF4C36" : "#65a0f9",
                      }}
                    >
                      {isNaN(item.result) ? "-" : item.result}{" "}
                    </span>
                    {`(of ${item.maxScore})`}
                  </h6>
                </div>
              </div>
            );
          })}
        </div>
      </DialogContent>
      <DialogActions
        style={{
          display: "flex",
          justifyContent: "space-between",
          padding: "24px",
          backgroundColor: mode == "dark" ? "#212529" : "white",
        }}
      >
        <Button
          onClick={dialogFinishClose}
          variant="outlined"
          className={classes.buttonCancelFinish}
          disabled={disabledCancel ? disabledCancel : false}
        >
          Cancel
        </Button>
        <Button
          onClick={finishHandler}
          variant="contained"
          className={`${classes.buttonFinish} dialog-submit-button`}
        >
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default DialogSubmit;
