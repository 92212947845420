import React from "react";
import { makeStyles } from "@material-ui/core/styles";

// receive prop
// const Instruction = (props) => {
//     return( <div>Instruction</div>
//     );
const useStyles = makeStyles({
  instruction: {
    display: "flex",
    maxHeight: "70vh",
    overflow: "auto",
    padding: "10px",
    fontWeight: "normal",
    " > *": {
      color: "white !important",
    },
  },
});

const Instruction = (props) => {
  const classes = useStyles();

  React.useEffect(() => {
    let nodes = document.querySelectorAll(".instabody>p");
    for (var i = 0; i < nodes.length; i++) {
      if (nodes[i].nodeName.toLowerCase() == "p") {
        nodes[i].style.color = "white";
      }
    }
  }, []);
  return (
    <div className={`${classes.instruction} instabody`}>
      <div
        className=""
        style={{ color: "white!important" }}
        dangerouslySetInnerHTML={{
          __html: props.data
            .replaceAll("rgb(254, 254, 254)", "transparent")
            .replaceAll("white", "transparent")
            .replaceAll("#fefefe", "transparent")
            .replaceAll("#000000", "white")
            .replaceAll("rgb(0, 0, 0)", "white")
            .replaceAll("#333333", "white")
            .replaceAll("rgb(51, 51, 51)", "white"),
        }}
      />
    </div>
  );
  return;
};

export default Instruction;
