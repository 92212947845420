import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Button, TextField } from "@material-ui/core";
import { Redirect } from "react-router-dom";
import Axios from "axios";
import Swal from "sweetalert2";
import bgImage from "../../assets/images/login.png";
import Loading from "../loading/Loading";
import ReactGA from "react-ga";

const useStyles = makeStyles({
  containerLogin: {
    height: "100vh",
    width: "100vw",
    display: "flex",
    justifyContent: "space-between",
    fontFamily: "'Poppins', sansSerif",
  },
  leftSideContainer: {
    flex: 1,
    backgroundImage: `url(${bgImage})`,
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center center",
  },
  rightSideContainer: {
    flex: 1,
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    padding: "30px",
  },
  groupOpening: {
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    marginBottom: "20px",
    padding: "0px 50px",
    textAlign: "center",
  },
  textOpening: {
    color: "#97A0B2",
    fontSize: "16px",
    fontWeight: "500px",
  },
  groupInput: {
    display: "flex",
    flexDirection: "column",
  },
  buttonSubmit: {
    color: "#fff",
    textTransform: "none",
    fontSize: "12px",
    backgroundColor: "#2761BA",
    border: "1px solid #F4991E",
    borderRadius: "5px",
    padding: "7px 40px",
    margin: "40px 0px",
    "&:hover": {
      backgroundColor: "#2761BA",
      color: "#fff",
    },
  },
  buttonIntruction: {
    textTransform: "none",
    border: "1px solid #234B88",
    color: "#234B88",
    fontWeight: "500px",
    fontSize: "12px",
  },
  submitGroup: {
    display: "flex",
    justifyContent: "end",
  },
});

const NewLogin = (props) => {
  const classes = useStyles();
  const [redirect, setRedirect] = useState(null);
  const [loading, setLoading] = useState(false);
  const [dataUser, setDataUser] = useState({
    email: "",
    code: "",
  });

  useEffect(() => {
    if (process.env.REACT_APP_IS_TRACK === "true") {
      ReactGA.pageview("/login-assessment");
    }
  }, []);

  const submitHandler = () => {
    setLoading(true);
    Axios.post(
      `${process.env.REACT_APP_API_END_POINT}/assessment/verifikasicode`,
      dataUser
    )
      .then((res) => {
        localStorage.setItem("auth", true);
        //console.log(res.data);
        setRedirect({
          pathname: "/test",
          state: {
            dataAssessment: res.data.assessment,
            listQuestion: res.data.assessment.list_question,
            dataMember: res.data.member,
            timer: res.data.timer,
            startTest: Date.now(),
          },
        });
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        // console.log(err.response.data.message);
        if (err.response.data.message == "Times up") {
          Swal.fire({
            text: "Times Up !",
            icon: "error",
            showCloseButton: true,
          });
        } else if (err.response.data.message == "Wrong Assessment Code") {
          Swal.fire({
            text: "Invalid Assessment Code",
            icon: "error",
            showCloseButton: true,
          });
        } else if (err.response.data.message == "Email Not Found") {
          Swal.fire({
            text: "Invalid Email",
            icon: "error",
            showCloseButton: true,
          });
        } else {
          Swal.fire({
            text: "Email or Assessment Code Not Verified",
            icon: "error",
            showCloseButton: true,
          });
        }
      });
  };

  const changeHandler = (event) => {
    let nama = event.target.name;
    let value = event.target.value;
    setDataUser({
      ...dataUser,
      [nama]: value,
    });
  };

  const renderRedirect = () => {
    if (redirect) {
      return <Redirect to={redirect} />;
    }
  };

  if (loading) {
    return <Loading />;
  } else {
    return (
      <>
        {renderRedirect()}
        <div className={classes.containerLogin}>
          <div className={classes.leftSideContainer}></div>
          <div className={classes.rightSideContainer}>
            <div className={classes.groupOpening}>
              <h3>Hi Developer !</h3>
              <p className={classes.textOpening}>
                Welcome to our assessment. Before taking the test, please
                complete your email and ranking code first to take it
              </p>
            </div>
            <div className={classes.groupInput}>
              <div style={{ padding: "5px 0px" }}>
                <p>
                  Email <span style={{ color: "red" }}>*</span>
                </p>
                <TextField
                  id="outlined-basic"
                  hiddenLabel
                  placeholder="Input Email"
                  size="small"
                  variant="outlined"
                  name="email"
                  style={{ width: "100%" }}
                  value={dataUser.email}
                  onChange={changeHandler}
                />
              </div>
              <div style={{ padding: "5px 0px" }}>
                <p>
                  Assessment Code <span style={{ color: "red" }}>*</span>
                </p>
                <TextField
                  id="outlined-basic"
                  hiddenLabel
                  placeholder="Input Code"
                  size="small"
                  variant="outlined"
                  name="code"
                  style={{ width: "100%" }}
                  value={dataUser.code}
                  onChange={changeHandler}
                />
              </div>
              <div style={{ marginTop: "10px" }}>
                <a style={{ textDecoration: "none" }} href="/guide">
                  <Button
                    className={classes.buttonIntruction}
                    variant="outlined"
                    size="small"
                  >
                    <i
                      className="fas fa-info-circle"
                      style={{ marginRight: "5px" }}
                    ></i>
                    View Instruction
                  </Button>
                </a>
              </div>
              <div className={classes.submitGroup}>
                <Button
                  className={classes.buttonSubmit}
                  onClick={submitHandler}
                  type="submit"
                  variant="contained"
                >
                  Submit
                </Button>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
};

export default NewLogin;
