import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Redirect } from "react-router-dom";
import Axios from "axios";

// loading
import Lottie from "react-lottie";
import * as loadingAnimation from "./23888-website-build.json";
import ReviewAssess from "../review/ReviewAssess";

const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData: loadingAnimation.default,
  speed: "1.5",
  renderSettings: {
    preserveAspectRatio: "xMidYMid slice",
  },
  backgroundColor: "#13335f",
};

const useStyles = makeStyles({
  containerLogin: {
    height: "100vh",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
  },
  textAuth: {
    fontFamily: "'Poppins', sansSerif",
    marginTop: "-20px",
    fontSize: "16px",
  },
});

const Auth = (props) => {
  const classes = useStyles();
  const [redirect, setRedirect] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getDataAssessment();
  }, []);

  const getDataAssessment = () => {
    let code = props.match.params.code;
    let uniqekey = props.match.params.uniqekey;

    let review = props.match.params.review;
    let reviewtask = props.match.params.reviewtask;

    // console.log("review");

    if (code) {
      if (uniqekey) {
        takeTestUniqe(code, uniqekey);
      } else {
        takeTest(code);
      }
      // console.log("from code" + code);
    }

    if (review) {
      reviewTask(review);
      // console.log("from review");
    }

    if (reviewtask) {
      console.log("from user review");

      reviewTaskUser(reviewtask);
    }
  };

  const takeTest = async (code) => {
    try {
      let result = await getDetailTaskAplicant(code);
      // console.log("%cAuth.js line:108 result", "color: #007acc;", result);
      if (result.data.isTaken) {
        takeTaskApplicant(code);
      } else {
        // localStorage.setItem("auth", true);
        setRedirect({
          pathname: "/",
          state: {
            // dataAssessment: res.data,
            // listQuestion: res.data.assessment.list_question,
            // dataMember: res.data.member,
            data: result.data,
            timer: result.data.timer,
            startTest: Date.now(),
            review: false,
            uniqe: false,
            code: code,
          },
        });
      }
    } catch (err) {
      if (err?.response?.data?.message == "Times up") {
        setRedirect({ pathname: "/timesup" });
      } else {
        setRedirect({ pathname: "/notverified" });
      }
      // console.log("%cAuth.js line:110 error", "color: #007acc;", error);
    }
    // Axios.get(
    //   `${process.env.REACT_APP_API_END_POINT}/assessment/taketask/${code}`
    // )
    //   .then((res) => {
    //     // console.log(res);
    //     localStorage.setItem("auth", true);
    //     setRedirect({
    //       pathname: "/",
    //       state: {
    //         dataAssessment: res.data,
    //         listQuestion: res.data.assessment.list_question,
    //         dataMember: res.data.member,
    //         timer: res.data.timer,
    //         startTest: Date.now(),
    //         review: false,
    //         uniqe: false,
    //       },
    //     });
    //   })
    //   .catch((err) => {
    //     if (err.response.data.message == "Times up") {
    //       setRedirect({ pathname: "/timesup" });
    //     } else {
    //       setRedirect({ pathname: "/notverified" });
    //     }
    //   });
  };

  const takeTestUniqe = async (code, uniqe) => {
    try {
      let result = await getDetailTask(code, uniqe);

      if (result.data.isTaken) {
        takeTaskStudent(code, uniqe);
      } else {
        // localStorage.setItem("auth", true);
        setRedirect({
          pathname: "/",
          state: {
            // dataAssessment: res.data,
            // listQuestion: res.data.assessment.list_question,
            // dataMember: res.data.member,
            data: result.data,
            timer: result.data.timer,
            startTest: Date.now(),
            review: false,
            uniqe: uniqe,
            code: code,
          },
        });
      }
    } catch (err) {
      if (err?.response?.data?.message == "Times up") {
        setRedirect({ pathname: "/timesup" });
      } else {
        setRedirect({ pathname: "/notverified" });
      }
      // console.log("%cAuth.js line:110 error", "color: #007acc;", error);
    }
  };

  const reviewTask = (code) => {
    Axios.get(
      `${process.env.REACT_APP_API_END_POINT}/assessment/reviewtask/${code}`
    )
      .then((res) => {
        console.log(res);
        localStorage.setItem("auth", true);
        setRedirect({
          pathname: "/review",
          state: {
            dataAssessment: res.data.assessment,
            listQuestion: res.data.assessment.list_question,
            dataMember: res.data.member,
            timer: res.data.timer * 60,
            startTest: Date.now(),
            review: true,
            isUser: false,
          },
        });
      })
      .catch((err) => {
        console.log(err);
        // if (err.response.data.message == "Times up") {
        //   setRedirect({ pathname: "/timesup" });
        // } else {
        //   setRedirect({ pathname: "/notverified" });
        // }
      });
  };

  const reviewTaskUser = (code) => {
    Axios.get(
      `${process.env.REACT_APP_API_END_POINT}/assessment/reviewtaskuser/${code}`
    )
      .then((res) => {
        console.log(res);
        localStorage.setItem("auth", true);
        setRedirect({
          pathname: "/review",
          state: {
            dataAssessment: res.data.assessment,
            listQuestion: res.data.assessment.list_question,
            dataMember: res.data.member,
            timer: res.data.timer,
            startTest: Date.now(),
            review: true,
            isUser: true,
          },
        });
      })
      .catch((err) => {
        console.log(err);
        // if (err.response.data.message == "Times up") {
        //   setRedirect({ pathname: "/timesup" });
        // } else {
        //   setRedirect({ pathname: "/notverified" });
        // }
      });
  };

  const renderRedirect = () => {
    if (redirect) {
      return <Redirect to={redirect} />;
    }
  };

  const getDetailTask = async (code, clodeID) => {
    // let code = props.match.params.code;

    return Axios.get(
      `${process.env.REACT_APP_API_END_POINT}/getdetailtaskstudent/${code}/${clodeID}`
    );

    //console.log(code);
  };

  const getDetailTaskAplicant = async (code) => {
    //getdetailtaskapplicantV2/
    return Axios.get(
      `${process.env.REACT_APP_API_END_POINT}/getdetailtaskapplicantV2/${code}`
    );
  };

  const takeTaskApplicant = async (code) => {
    Axios.get(
      `${process.env.REACT_APP_API_END_POINT}/assessment/taketask/${code}`
    )
      .then((res) => {
        // console.log(res);
        localStorage.setItem("auth", true);
        setRedirect({
          pathname: "/test",
          state: {
            dataAssessment: res.data.assessment,
            listQuestion: res.data.assessment.list_question,
            dataMember: res.data.member,
            timer: res.data.timer,
            startTest: Date.now(),
            review: false,
            uniqe: false,
          },
        });
      })
      .catch((err) => {
        if (err.response.data.message == "Times up") {
          setRedirect({ pathname: "/timesup" });
        } else {
          setRedirect({ pathname: "/notverified" });
        }
      });
  };

  const takeTaskStudent = async (code, uniqe) => {
    Axios.get(
      `${process.env.REACT_APP_API_END_POINT}/assessment/taketask/${code}/${uniqe}`
    )
      .then((res) => {
        // console.log(res);
        localStorage.setItem("auth", true);
        setRedirect({
          pathname: "/test",
          state: {
            dataAssessment: res.data.assessment,

            listQuestion: res.data.assessment.list_question,
            dataMember: res.data.member,
            timer: res.data.timer,
            startTest: Date.now(),
            review: false,
            uniqe: uniqe,
          },
        });
      })
      .catch((err) => {
        console.log(err.response.data.message);
        if (err.response.data.message == "Times up") {
          setRedirect({ pathname: "/timesup" });
        } else {
          setRedirect({ pathname: "/notverified" });
        }
      });
  };

  return (
    <>
      {renderRedirect()}
      <div className={classes.containerLogin}>
        <Lottie
          options={defaultOptions}
          isClickToPauseDisabled={true}
          height={300}
          width={400}
        />
        <span className={classes.textAuth}>
          Oasys mu sedang di generate.. Mohon tunggu sebentar yak..
        </span>
      </div>
    </>
  );
};

export default Auth;
