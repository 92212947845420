import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@material-ui/core";
import { PlayCircleOutline, Cached } from "@material-ui/icons";

// text editor
import AceEditor from "react-ace";
import "ace-builds/src-noconflict/mode-csharp";
import "ace-builds/src-noconflict/mode-javascript";
import "ace-builds/src-noconflict/theme-dracula";
import "ace-builds/src-noconflict/ext-language_tools";

let primaryColorDark = "#212B31";
let neutral_n95 = "#F5F8FF";
let neutral_n100 = "#FFFFFF";

const useStyles = makeStyles({
  containerInput: {
    width: "50vw",
    backgroundColor: primaryColorDark,
    display: "flex",
    flexDirection: "column",
    // backgroundColor: "#54ca68",
  },
  dropdownInput: {
    width: "100%",
    display: "inline-flex",
    alignItems: "center",
    padding: "10px 24px",
    borderBottom: "1px solid rgba(255, 255, 255, 0.2)",
  },
  containerSoal: {
    padding: "20px",
    width: "50vw",
    backgroundColor: "#3abaf4",
    fontWeight: "600",
  },
  containerButtonInput: {
    display: "flex",
    justifyContent: "center",
    // marginTop: "10px",
    padding: "12px",
  },
  buttonRun: {
    color: "#fff",
    textTransform: "none",
    fontSize: "12px",
    backgroundColor: "#2761BA",
    border: "1px solid #F4991E",
    borderRadius: "5px",
    padding: "7px 30px",
    "&:hover": {
      backgroundColor: "#2761BA",
      color: "#fff",
    },
  },
  buttonReset: {
    color: "#234B88",
    textTransform: "none",
    fontSize: "12px",
    backgroundColor: "#FFF",
    border: "1px solid #234B88",
    borderRadius: "5px",
    padding: "7px 30px",
    "&:hover": {
      backgroundColor: "#FFF",
      color: "#234B88",
    },
  },
  buttonPopUpCancel: {
    color: "#2761BA",
    textTransform: "none",
    fontFamily: "'Poppins', sansSerif",
    backgroundColor: "#fff",
    border: "1px solid #2761BA",
    padding: "12px 48.5px",
  },
  buttonPopUpAction: {
    color: "#fff",
    textTransform: "none",
    fontFamily: "'Poppins', sansSerif",
    backgroundColor: "#2761BA",
    border: "1px solid #2761BA",
    padding: "12px 48.5px",
  },
});

const input = `using System;
					
public class Program
{
	public static void Main()
	{
		int countF = Convert.ToInt16(Console.ReadLine());
		
		string[] countM = Console.ReadLine().Split(' ');
		int result = 0;
		
		//complete the PSBB function below
			
			
		Console.WriteLine("Minimum bus required is : " + result);
			
	}
}`;

const UserInputExample = () => {
  const classes = useStyles();
  return (
    <div className={classes.containerInput}>
      <div className={classes.dropdownInput}>
        {/* <div className="question-guide">
                    <label htmlFor="problems">
                        <p>Questions</p>
                    </label>

                    <select
                      
                        id="problems"
                        className="form-control form-inline mb-2 mx-2 language"
                    >
                        <option>TEST001 - PSBB</option>
                        <option>TEST002 - Parking Bus</option>
                        <option>TEST003 - Box</option>
                    </select>
                </div> */}
        <div className="language-guide">
          <label htmlFor="language">
            <p style={{ color: neutral_n100, marginBottom: 0 }}>Language</p>
          </label>
          <select
            // value={languageId}
            // onChange={changeLanguageHandler}
            id="tags"
            className="form-control form-inline mb-2 mx-2 language"
          >
            <option>C#</option>
            <option>Javascript</option>
            <option>PHP</option>
          </select>
        </div>
      </div>
      <div
        className="editor-guide"
        style={{
          padding: "24px 24px 24px 0px",
          borderBottom: "1px solid rgba(255, 255, 255, 0.2)",
          flex: 1,
        }}
      >
        <AceEditor
          id="source"
          className=" source"
          placeholder="Source Code"
          mode={"csharp"}
          theme="dracula"
          name="solution"
          // onChange={changeInputHandler}
          fontSize={14}
          showPrintMargin={true}
          showGutter={true}
          highlightActiveLine={true}
          value={input}
          width="100%"
          height="60vh"
          setOptions={{
            enableBasicAutocompletion: true,
            enableLiveAutocompletion: true,
            enableSnippets: true,
            showLineNumbers: true,
            tabSize: 4,
            wrap: true,
          }}
        />
      </div>
      <div className={classes.containerButtonInput}>
        <Button
          className={`${classes.buttonRun} run-guide`}
          // className={classes.buttonRun}
          // onClick={runHandler}
          variant="contained"
          style={{ marginRight: "10px", backgroundColor: "#3DC3DD" }}
        >
          <PlayCircleOutline
            fontSize="small"
            style={{ marginRight: "5px", color: "#2B2E33", fontWeight: "bold" }}
          />
          <span
            style={{ margin: "0px auto", color: "#2B2E33", fontWeight: "bold" }}
          >
            Run Code
          </span>
        </Button>

        <Button
          className={`${classes.buttonReset} reset-guide`}
          // onClick={dialogResetOpen}
          variant="outlined"
          style={{
            backgroundColor: primaryColorDark,
            color: "#3DC3DD",
            fontWeight: "bold",
          }}
        >
          <Cached fontSize="small" style={{ marginRight: "5px" }} />
          <span style={{ margin: "0px auto" }}>Reset Code</span>
        </Button>

        <Dialog
          // open={dialogReset}
          // onClose={dialogResetClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogContent dividers>
            <DialogContentText
              style={{ textAlign: "center" }}
              id="alert-dialog-description"
            >
              <p>Your written code will be removed</p>
              <p>are you sure to reset the code?</p>
              <div style={{ paddingTop: "10px" }}>
                <Button
                  className={classes.buttonPopUpCancel}
                  // onClick={dialogResetClose}
                  variant="contained"
                  style={{ marginLeft: "10px" }}
                >
                  <span style={{ margin: "0px auto" }}>Cancel</span>
                </Button>
                <Button
                  className={classes.buttonPopUpAction}
                  // onClick={resetHandler}
                  variant="contained"
                  style={{ marginLeft: "10px" }}
                >
                  <span style={{ margin: "0px auto" }}>Reset</span>
                </Button>
              </div>
            </DialogContentText>
          </DialogContent>
        </Dialog>
      </div>
    </div>
  );
};

export default UserInputExample;
