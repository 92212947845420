import React from "react";
import Grid from "@material-ui/core/Grid";

import NoSolutionImage from "../../assets/images/NoSolution.png";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    overflowY: "scroll",
    height: "calc(100vh - 30vh)",
  },
  image: {
    margin: "0 auto",
  },
  containerImage: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  label: {
    marginTop: "16px",
    color: "#B8BECC",
  },
}));
export default function NoSolution() {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Grid container spacing={0}>
        <Grid className={classes.containerImage} item xs={12} lg={12}>
          <img
            className={classes.image}
            src={NoSolutionImage}
            alt="No Solution"
          />
        </Grid>
        <Grid className={classes.containerImage} item xs={12} lg={12}>
          <p className={classes.label}>
            The solution will be dicussed in class
          </p>
        </Grid>
      </Grid>
    </div>
  );
}
