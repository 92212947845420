import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import ReactGA from "react-ga";

// loading
import Lottie from "react-lottie";
import * as loadingAnimation from "./86538-error-404.json";

const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData: loadingAnimation.default,
  speed: "1.5",
  renderSettings: {
    preserveAspectRatio: "xMidYMid slice",
  },
  backgroundColor: "#13335f",
};

const useStyles = makeStyles((theme) => ({
  content: {
    backgroundColor: "white",
    padding: "20px",
    height: "100vh",
    fontFamily: "'Poppins', sansSerif",
  },
  containerResend: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    height: "90%",
    alignContent: "center",
    justifyContent: "center",
    backgroundColor: "white",
  },
  containerResendText: {
    marginBottom: "30px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
  resendTitle: {
    fontSize: "28px",
    fontWeight: "600",
    margin: "10px 0",
    color: "#2761BA",
  },
  resendText: {
    textAlign: "center",
    fontSize: "14px",
    fontWeight: "400",
  },
}));

const NotFound = () => {
  const classes = useStyles();

  useEffect(() => {
    if (process.env.REACT_APP_IS_TRACK === "true") {
      ReactGA.pageview("/login-assessment");
    }
  }, []);

  return (
    <div className={classes.content}>
      <div className={classes.containerResend}>
        <Lottie
          options={defaultOptions}
          isClickToPauseDisabled={true}
          height={200}
          width={300}
        />
        <br />
        <h2 className={classes.resendTitle}>Page not Found</h2>
        <div className={classes.containerResendText}>
          <p className={classes.resendText}>
            Sorry, we couldn’t find the page you are looking for
          </p>
        </div>
      </div>
    </div>
  );
};

export default NotFound;
